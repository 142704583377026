import React, { useEffect } from 'react';

import LoginPage from '../components/LoginPage';


const LoginPage2 = () => {

  return (<>
    <LoginPage />
  </>
  );
};

export default LoginPage2;
