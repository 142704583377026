import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '20%', // Add top margin of 50%
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  formContainer: {
    padding: '20px', // Add padding to the form container
    width: '75%', // Set the width to 75% to make it appear as 75% of the page
  },
  form: {
    width: '70%', // Fix IE 11 issue.
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the form elements horizontally
  },
  submit: {
    margin: '10px',
  },
}));

const LoginPage = () => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can handle form submission logic here
  };

  return (

    <><Container component="main" maxWidth="xs" style={{ display: 'flex' }}>

      <h1>welcome to Solibri</h1>

    </Container><Container component="main" maxWidth="xs" style={{ display: 'flex' }}>
      <Paper elevation={3} className={classes.root}>
        <img src="https://www.dqindia.com/wp-content/uploads/2023/01/Four-ways-technology-will-drive-innovation-in-2023.jpg" alt="Sample image" />

        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              value={formData.username}
              onChange={handleInputChange} />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formData.password}
              onChange={handleInputChange} />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
                Sign In
            </Button>
          </form>
        </div>
      </Paper>
    </Container></>
  );
};

export default LoginPage;